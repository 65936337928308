<template>
  <div v-if="!isFetching">
    <div v-for="item in tableServidor" :key="item.idUsuarioEntidade">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">
            Servidor Selecionado
          </h4>
        </md-card-header>
        <md-card-content>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nome Completo</label>
                <md-input
                  name="Nome Completo"
                  v-model="item.nomeCompleto"
                  type="text"
                  :maxlength="250"
                  class="uppercase"
                  disabled
                ></md-input>
                <!--<p id="error">{{ errors[0] }}</p>-->
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>CPF</label>
                <md-input
                  name="CPF Servidor"
                  v-model="item.cpfServidor"
                  type="text"
                  :maxlength="14"
                  class="uppercase"
                  disabled
                ></md-input>
                <!--<p id="error">{{ errors[0] }}</p>-->
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Siape Servidor</label>
                <md-input
                  name="Siape Servidor"
                  v-model="item.siapeServidor"
                  type="text"
                  :maxlength="20"
                  class="uppercase"
                  disabled
                ></md-input>
                <!--<p id="error">{{ errors[0] }}</p>-->
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Data Nascimento</label>
                <md-input
                  name=""
                  v-model="item.dataNascimento"
                  type="text"
                  :maxlength="10"
                  class="uppercase"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Sexo</label>
                <md-input
                  name=""
                  v-model="item.sexoServidor"
                  type="text"
                  :maxlength="20"
                  class="uppercase"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-66">
              <md-field>
                <label>Nome Entidade</label>
                <md-input
                  name="Nome Entidade"
                  v-model="item.nomeEntidade"
                  type="text"
                  :maxlength="250"
                  disabled
                ></md-input>
              </md-field>
            </div>
          </div>
          <br />
          <!-- <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <h5 class="title" style="font-weight: 400;">
                Servidor Relacionado
              </h5>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-table
                    :key="componentKeyToRerender"
                    v-model="tableServidorServidor"
                    class="table-striped"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Siape Servidor">{{
                        item.siapeServidor
                      }}</md-table-cell>
                      <md-table-cell md-label="Parentesco">{{
                        item.parentesco
                      }}</md-table-cell>

                      <md-table-cell md-label="Siape Servidor">{{
                        item.siapeServidor
                      }}</md-table-cell>

                      <md-table-cell md-label="CPF Servidor">{{
                        cpfMaskCall(
                          item.cpfServidor && item.cpfServidor !== null
                            ? item.cpfServidor
                            : "N/A"
                        )
                      }}</md-table-cell>
                      <md-table-cell md-label="Nome Completo">{{
                        item.nomeCompleto && item.nomeCompleto !== null
                          ? item.nomeCompleto
                          : "N/A"
                      }}</md-table-cell>
                      <md-table-cell md-label="Data Nascimento">{{
                        item.dataNascimento
                      }}</md-table-cell>
                      <md-table-cell md-label="SEXO">{{
                        (item.sexoServidor =
                          item.sexoServidor === "F" ? "Feminino" : "Masculino")
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </div>
          </div> -->
        </md-card-content>
      </md-card>
    </div>

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>folder</md-icon>
        </div>
        <h4 class="title">
          Arquivos
        </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-table
              :key="componentKeyToRerender"
              v-model="tableArquivos"
              class="table-striped"
            >
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <!--<md-table-cell md-label="Id">{{
                          item.idUsuarioEntidade
                        }}</md-table-cell>-->
                <md-table-cell md-label="Data">{{
                  item.dataModificacao.split(" ")[0]
                }}</md-table-cell>
                <md-table-cell md-label="Versão">{{
                  item.versao && item.versao !== null ? item.versao : "N/A"
                }}</md-table-cell>

                <md-table-cell md-label="Nome Arquivo">{{
                  item.nomeArquivo && item.nomeArquivo !== null
                    ? item.nomeArquivo
                    : "N/A"
                }}</md-table-cell>

                <md-table-cell md-label="Pasta/Objeto">{{
                  item.nomePasta && item.nomePasta !== null
                    ? item.nomePasta
                    : "N/A"
                }}</md-table-cell>
                <md-table-cell
                  md-label="Download"
                  :class="getAlignClasses(item)"
                >
                  <md-button
                    class="md-just-icon md-success md-round"
                    @click="
                      downloadId(item.idArquivo, item.nomeArquivo, item.versao)
                    "
                    ><md-icon>get_app</md-icon></md-button
                  >
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div v-if="tableArquivos.length <= 0" class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-100">
                <br />
                <h5>
                  Não há Arquivos !
                </h5>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <br />
    <div class="md-layout">
      <div class="md-layout-item md-size-100 text-right">
        <span>&nbsp;</span>
      </div>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import {
  downloadUser,
  instance
} from "../../arquivo/js/file-upload.download.service";
import { getLocalToken } from "@/modules/auth/storage";
import { siapeServidorMask } from "@/pages/Dashboard/js/siapeMask";

const axiosInstance = instance;

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile,
    reloadEntidadeTable: function() {
      return this.tableServidor;
    }
  },
  data() {
    return {
      componentKeyToRerender: 0,
      entidadeSelect: "",
      selectedServidorId: this.id,
      isFetching: true,
      idade: 0,
      mensagem: "",
      papel: "",
      tableServidor: [],
      tableServidorServidor: [],
      tableServidorBody: "",
      showServidor: false,
      tableArquivos: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.loadServidor();
    const token = getLocalToken();
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    this.LogAtividade(
      window._Vue.$route.path,
      "PAGINA VISUALIZAÇÃO SERVIDOR".toUpperCase(),
      "ACESSO".toUpperCase()
    );
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_publico", ["ActionGetServidorPublicoById"]),
    ...mapActions("entidade", ["ActionGetEntidadeById"]),
    ...mapActions("arquivo", ["ActionGetArquivoFromServ"]),
    ...mapActions("log_atividades", ["ActionPOSTLogAtividade"]),

    async LogAtividade(rota, destino, atividade) {
      try {
        let id = this.user_profile_state.idUsuario;
        let payload = {
          id: id,
          nomeusuario: this.user_profile_state.nomeusuario,
          email: this.user_profile_state.email,
          rota: rota,
          destino: destino,
          atividade: atividade,
          papel: this.user_profile_state.papel
        };
        await this.ActionPOSTLogAtividade(payload).then(resp => {});
      } catch (err) {
        this.handleError(err);
      }
    },
    downloadId(id, nome, versao) {
      downloadUser(axiosInstance, id, nome, versao, this.papel).then(
        filename => {
          let rota = `/api/v1/user/servidorpublico/arquivo/download/${id}/${versao}`;

          this.LogAtividade(rota, filename, "DOWNLOAD".toUpperCase());
        }
      );
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    loadForm() {
      if (this.tableServidor.length == 1) {
        this.tableServidor[0].idServidor;
        this.forceRerender();
        this.getArquivoFromServ().then(() => {});

        this.tableServidor.forEach(element => {
          Object.keys(element).map(key => {
            element[key] =
              key === "cpfServidor" && element[key] !== null
                ? cpfMask(element[key])
                : element[key];

            element[key] =
              key === "siapeServidor" && element[key] !== null
                ? siapeServidorMask(element[key])
                : element[key];

            if (key === "sexoServidor" && element[key] === "F") {
              element[key] = "Feminino";
            } else if (key === "sexoServidor" && element[key] === "M") {
              element[key] = "Masculino";
            }
            return element[key];
          });
        });
      }

      //this.getServidorFromPen();

      this.isFetching = false;
    },
    onSelect(value) {
      console.log(value);
    },
    async loadServidor() {
      this.getServidorPublicoById().then(() => {
        this.loadForm();
      });
    },
    async getServidorPublicoById() {
      try {
        let id = this.id;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        let response = await this.ActionGetServidorPublicoById({
          id,
          papel,
          idEntidade
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                title: `idServidor: ${idServidor} não encontrado.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idServidor: ${idServidor}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            this.tableServidor[0] = response.body;
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            this.entidadeSelect = response.body;
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getArquivoFromServ() {
      try {
        let id = this.id;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        let response = await this.ActionGetArquivoFromServ({
          id,
          papel,
          idEntidade
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              //this.tableArquivos = [];
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idServidor: ${idServidor}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            this.tableArquivos = response.body;
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    calcularIdade(dobString) {
      let dob = new Date(dobString);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let birthdayThisYear = new Date(
        currentYear,
        dob.getMonth(),
        dob.getDate()
      );
      let age = currentYear - dob.getFullYear();
      if (birthdayThisYear > currentDate) {
        age--;
      }
      return age;
    },
    callIdadeCalc(dataNascimento) {
      if (dataNascimento.toString().length < 10) {
        return 0;
      }
      let data = dataNascimento;
      let partes = data.split("/");
      let join = partes[2] + "-" + partes[1] + "-" + partes[0];
      return this.calcularIdade(join);
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getAlignClasses: ({ id }) => ({
      "text-right": id
    }),
    cpfMaskCall(value) {
      return cpfMask(value);
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 600;
}

::v-deep .md-table table {
  font-weight: 500;
}

.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

.uppercase {
  text-transform: uppercase;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

.switch-div {
  /*display: inline-block;*/
  float: right;
}
</style>
