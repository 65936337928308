<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment_ind</md-icon>
          </div>
          <h4 class="title">Tabela de Pensionistas</h4>
          <h5 class="title">
            Aqui é possivel criar novos Pensionistas e editar e excluí-los.
          </h5>
        </md-card-header>
        <md-card-content>
          <div class="md-size-100 text-left">
            <md-button
              class="md-raised md-success mt-4"
              @click="goToNovoPensionista"
              v-show="checkPapel()"
            >
              Novo Pensionista
            </md-button>
          </div>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <label for="filtro">Filtrar por Entidade</label>
                <md-select
                  v-if="!isFetching2"
                  v-model="filtroEntidade"
                  name="filtroEntidade"
                  @md-selected="onChangeEntidade"
                >
                  <md-option
                    v-for="item in entidadeFilter"
                    :key="item.idEntidade"
                    :label="item.nomeEntidade"
                    :value="item.nomeEntidade"
                  >
                    {{ item.nomeEntidade }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Siape"
                  v-model="searchQuerySiapePensionista"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="CPF"
                  v-model="searchQueryCPF"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Nome Completo"
                  v-model="searchQueryNomeCompleto"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Unidade Pagadora"
                  v-model="searchQueryUpagDescricao"
                >
                </md-input>
              </md-field>
              <div class="md-toolbar-section-start">
                <md-button
                  class="size35 md-just-icon md-simple md-success"
                  @click.native="handleSearch()"
                >
                  <md-icon>search</md-icon>
                </md-button>
              </div>
            </md-table-toolbar>
            <br />
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Id Pensionista"
                md-sort-by="idPensionista"
                >{{ item.idPensionista }}</md-table-cell
              >
              <md-table-cell md-label="Siape" md-sort-by="siapePensionista">{{
                siapePensionistaMaskCall(item.siapePensionista)
              }}</md-table-cell>
              <md-table-cell md-label="CPF" md-sort-by="cpfPensionista">{{
                callCpfMask(item.cpfPensionista)
              }}</md-table-cell>
              <md-table-cell
                md-label="Nome Completo"
                md-sort-by="nomeCompleto"
                >{{ item.nomeCompleto }}</md-table-cell
              >
              <md-table-cell
                md-label="Unidade Pagadora"
                md-sort-by="upagDescricao"
                >{{ item.upagDescricao }}</md-table-cell
              >
              <md-table-cell md-label="Entidade" md-sort-by="nomeEntidade">{{
                item.nomeEntidade
              }}</md-table-cell>

              <md-table-cell md-label="Ações" v-if="totalRegistros > 0">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click="PensionistaViewer(item)"
                >
                  <md-tooltip md-direction="top">Visualizar</md-tooltip>
                  <md-icon>visibility</md-icon>
                </md-button>

                <md-button
                  class="md-just-icon md-success md-simple"
                  @click.native="handleEdit(item)"
                  v-show="checkPapel()"
                >
                  <md-tooltip md-direction="top">Editar</md-tooltip>
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                  v-show="checkPapel()"
                >
                  <md-tooltip md-direction="top">Excluir</md-tooltip>
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.CPF"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { siapePensionistaMask } from "@/pages/Dashboard/js/siapeMask";

var pt_br = pt_BR;
var names = {};
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    }
  },
  components: {
    Pagination
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("papel", {
      papelSelected: "papelSelectedState"
    }),
    user_profile_state: state => state.user_profile,

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.tableData.length > 0) {
        return result;
      } else {
        return [];
      }
    },
    to() {
      if (this.totalRegistros === 0) {
        return 0;
      } else {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if (this.totalRegistros === 0) {
        return -1;
      } else {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    total() {
      return this.totalRegistros;
    },
    reload: function() {
      this.getEntidadeAll();
      this.getPensionistasPagination();
      return null;
    }
  },
  data() {
    return {
      mensagem: "",
      isFetching: true,
      isFetching2: true,
      isFetching3: true,
      papel: "",
      currentSort: "nomeCompleto",
      currentSortOrder: "desc",
      entidadeFilter: [],
      filtroEntidade: "",
      filtroSituacao: null,
      totalRegistros: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200, 500, 1000],
        total: 0
      },
      footerTable: [
        "id Pensionista",
        "Siape",
        "CPF",
        "Nome Completo",
        "Unidade Pagadora",
        "Entidade",
        "Ações"
      ],
      searchQueryNomeCompleto: null,
      searchQueryCPF: null,
      searchQueryUpagDescricao: null,
      searchQuerySiapePensionista: null,
      idEntidadeSelected: null,
      tableData: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.getEntidadeAll();
    this.getPensionistasPagination();
    this.ActionSetENDERECO({});
    this.ActionSetTELEFONE({});
    this.ActionSetEMAIL({});
    this.ActionSetARQUIVO({});
    this.ActionSetPASTA({});
    this.ActionSetPENSIONISTA({});
  },
  methods: {
    ...mapActions("endereco", ["ActionSetENDERECO"]),
    ...mapActions("telefone", ["ActionSetTELEFONE"]),
    ...mapActions("email", ["ActionSetEMAIL"]),
    ...mapActions("arquivo", ["ActionSetARQUIVO", "ActionSetPASTA"]),
    ...mapActions("pensionista", [
      "ActionSetPENSIONISTA",
      "ActionGetPensionistasPagination",
      "ActionDELETEPensionista",
      "ActionGetPensionistasByQuery"
    ]),
    ...mapActions("papel", [
      "ActionGetPapelAll",
      "ActionGetPapelById",
      "ActionSetPapel"
    ]),
    ...mapActions("entidade", ["ActionGetEntidadeAll"]),
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    siapePensionistaMaskCall(value) {
      value =
        value !== "" && value !== null ? siapePensionistaMask(value) : null;
      return value;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    callCpfMask(value) {
      return cpfMask(value.toString());
    },
    checkAssingmentDisabled(item) {
      if (item.nomePapel === this.papelSelected.nomePapelSelected) {
        return "disabled";
      } else {
        return null;
      }
    },
    checkAssingmentStatus(item) {
      if (item.nomePapel === this.papelSelected.nomePapelSelected) {
        return "md-success";
      } else {
        return "md-info";
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getPensionistasPagination() {
      try {
        let papel = this.papel;
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        let idEntidade = this.user_profile_state.idEntidade;
        await this.ActionGetPensionistasPagination({
          papel,
          idEntidade,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.status === 200) {
            if (response.body.mensagem && response.body.mensagem !== null) {
              //console.log(response);
              this.tableData = [];
              this.totalRegistros = 0;
            } else {
              this.tableData = response.body.pensionista;
              this.totalRegistros = response.body.totalRegistros;
            }
          }
          this.isFetching2 = false;
          this.isFetching3 = false;
          this.isFetching = false;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getPensionistaPaginationNext() {
      try {
        let papel = this.papel;
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        let response = await this.ActionGetPensionistasPagination({
          papel,
          noOfRecords,
          pageIndex
        }).then(res => {
          this.tableData = res.body.pensionista;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        let response = await this.ActionGetEntidadeAll({ papel, idEntidade });
        if (this.papel === "ADMIN") {
          let entidadeFilter = response.body;
          entidadeFilter.push({
            nomeEntidade: ""
          });
          entidadeFilter.sort((a, b) => {
            if (a.nomeEntidade < b.nomeEntidade) return -1;
            if (a.nomeEntidade > b.nomeEntidade) return 1;
            return 0;
          });
          this.entidadeFilter = entidadeFilter;
        } else {
          this.entidadeFilter = [];
          this.entidadeFilter.push({
            nomeEntidade: this.user_profile_state.nomeEntidade
          });
          this.filtroEntidade = this.user_profile_state.nomeEntidade;
        }
        return response.body;
        /*let entidadeFilter = response.body;
        entidadeFilter.push({
          nomeEntidade: ""
        });
        entidadeFilter.sort((a, b) => {
          if (a.nomeEntidade < b.nomeEntidade) return -1;
          if (a.nomeEntidade > b.nomeEntidade) return 1;
          return 0;
        });
        this.entidadeFilter = entidadeFilter;
        return response.body;*/
      } catch (err) {
        this.handleError(err);
      }
    },
    goToPensionistaViewer(item) {
      this.ActionSetPENSIONISTA(item);
      let id = item.idPensionista;
      window._Vue.$router
        .push({ path: `/pensionistas/detalhe_pensionista/${id}` })
        .catch(err => {});
    },
    goToNovoPensionista() {
      this.ActionSetENDERECO({});
      this.ActionSetTELEFONE({});
      this.ActionSetEMAIL({});
      this.ActionSetARQUIVO({});
      this.ActionSetPASTA({});
      this.ActionSetPENSIONISTA({});
      window._Vue.$router.push({ name: "Novo Pensionista" }).catch(err => {});
    },
    handleError(err) {
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    async handleSearch() {
      if (
        (this.idEntidadeSelected === null || this.idEntidadeSelected === "") &&
        this.filtroEntidade === "" &&
        (this.searchQuerySiapePensionista === "" ||
          this.searchQuerySiapePensionista === null) &&
        (this.searchQueryCPF === "" || this.searchQueryCPF === null) &&
        (this.searchQueryNomeCompleto === "" ||
          this.searchQueryNomeCompleto === null) &&
        (this.searchQueryUpagDescricao === "" ||
          this.searchQueryUpagDescricao === null)
      ) {
        this.getPensionistasPagination();
      } else {
        let papel = this.papel;
        let idEntidade = null;
        let siapePensionista = null;
        let cpfPensionista = null;
        let nomeCompleto = null;
        let upagDescricao = null;
        if (this.papel === "ADMIN") {
          if (
            this.idEntidadeSelected !== null &&
            this.filtroEntidade !== "" &&
            this.idEntidadeSelected !== ""
          ) {
            idEntidade = this.idEntidadeSelected;
          }
        } else if (this.papel === "ADMIN_ENTIDADE") {
          idEntidade = this.user_profile_state.idEntidade;
        }
        if (
          this.searchQuerySiapePensionista !== "" &&
          this.searchQuerySiapePensionista !== null
        ) {
          siapePensionista = this.searchQuerySiapePensionista;
        }
        if (this.searchQueryCPF !== "" && this.searchQueryCPF !== null) {
          cpfPensionista = this.searchQueryCPF;
        }
        if (
          this.searchQueryNomeCompleto !== "" &&
          this.searchQueryNomeCompleto !== null
        ) {
          nomeCompleto = this.searchQueryNomeCompleto;
        }
        if (
          this.searchQueryUpagDescricao !== "" &&
          this.searchQueryUpagDescricao !== null
        ) {
          upagDescricao = this.searchQueryUpagDescricao;
        }
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        await this.ActionGetPensionistasByQuery({
          papel,
          idEntidade,
          siapePensionista,
          cpfPensionista,
          nomeCompleto,
          upagDescricao,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.body.mensagem) {
            if (
              response.body.mensagem.includes("Pensionistas não encontrados")
            ) {
              this.totalRegistros = 0;
              this.tableData = [
                {
                  idPensionista: "",
                  siapePensionista: "",
                  cpfPensionista: "",
                  nomeCompleto: "",
                  upagDescricao: "",
                  nomeEntidade: ""
                }
              ];
            }
          } else {
            this.tableData = response.body.pensionista;
            this.totalRegistros = response.body.totalRegistros;
          }
        });
      }
    },
    onChangeEntidade: async function() {
      if (this.filtroEntidade !== "") {
        let result = this.tableData;
        let nomeEntidade = this.filtroEntidade;
        let index = -1;
        let filteredResult = this.entidadeFilter.find(function(item, i) {
          if (item.nomeEntidade === nomeEntidade) {
            index = i;
            return item;
          }
        });
        let idEntidade = filteredResult.idEntidade;
        this.idEntidadeSelected = idEntidade;
      } else {
        this.filtroEntidade = "";
        this.idEntidadeSelected = "";
      }
    },
    handleDelete(item) {
      Swal.fire({
        title: "Você tem certeza ?",
        text: `Se você excluir o Pensionista todas as relações entre Pensionitas e o Servidor Publico e todos os dados tipo Emails, Endereços, Telefones deste Pensionista serão excluidos ! Isto será irreversível !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir isto!",
        cancelButtonText: "CANCELAR",
        customClass: {
          confirmButton: "md-button md-success md-raised mt-4",
          cancelButton: "md-button md-danger md-raised mt-4"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    async deleteRow(item) {
      try {
        let id = item.idPensionista;
        let papel = this.papel;
        let response = await this.ActionDELETEPensionista({ id, papel });
        if (response.status === 200) {
          let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.idPensionista === item.idPensionista
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          this.mensagem = response.body.mensagem;
          if (this.mensagem.includes("não encontrado")) {
            Swal.fire({
              title: `CPF: ${item.cpfPensionista} - ${item.nomeCompleto} Não Excluido.`,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `CPF: ${item.cpfPensionista} - ${item.nomeCompleto} Excluido!`,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
          this.reload;
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    handleEdit(item) {
      let id = item.idPensionista;
      let isEdition = true;
      window._Vue.$router
        .push({
          name: "Cadastro Pensionista",
          params: { id: id, isEdition: true }
        })
        .catch(err => {});
    }
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        if (
          (this.idEntidadeSelected === null ||
            this.idEntidadeSelected === "") &&
          this.filtroEntidade === "" &&
          (this.searchQuerySiapePensionista === "" ||
            this.searchQuerySiapePensionista === null) &&
          (this.searchQueryCPF === "" || this.searchQueryCPF === null) &&
          (this.searchQueryNomeCompleto === "" ||
            this.searchQueryNomeCompleto === null) &&
          (this.searchQueryUpagDescricao === "" ||
            this.searchQueryUpagDescricao === null)
        ) {
          this.getPensionistaPaginationNext();
        } else {
          this.handleSearch();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: 400;
}

.footer-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.md-table-cell {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.74rem;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

.size35 i {
  font-size: 35px !important;
}

::v-deep .md-toolbar-section-start {
  padding-bottom: 20px;
}

::v-deep .md-card .md-card-content {
  padding: 2px 20px;
}
</style>
